import { motion, useInView } from 'framer-motion';
import { useRef, useState } from 'react';

import { CaseStudyItem } from './CaseStudyItem';
import { CaseStudies } from './Data/CaseStudy';
import { MainContainer } from './MainContainer';

export const CaseStudy = () => {
	const [mainContent, setMainContent] = useState(CaseStudies[0]);
	const containerRef = useRef(null);
	const isInView = useInView(containerRef, { once: true });
	const itemVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: (i: number) => ({
			opacity: 1,
			y: 0,
			transition: {
				delay: i * 0.2, // Stagger effect
				duration: 0.5,
			},
		}),
	};
	return (
		<div className='fadedown lg:max-w-4xl w-100  mx-auto p-2'>
			<div className='text-center'>
				<h2 className='text-xl font-semibold leading-8 tracking-tight text-center text-yellow-500'>
					Case Studies
				</h2>
			</div>
			<div className='text-left'>
				<p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 text-left sm:text-4xl '>
					Transforming Careers and Businesses: Inspiring Success Stories from J&T
					Recruitment
				</p>
			</div>
			<div className='mt-4'>
				<div className='flex flex-col lg:flex-row justify-between gap-6' ref={containerRef}>
					<motion.div
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						variants={{
							hidden: { opacity: 0, y: 20 },
							visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
						}}
						className='w-full lg:w-1/2'
					>
						<MainContainer {...mainContent} />
					</motion.div>
					<div className='flex flex-col gap-4 justify-between w-full lg:w-1/2'>
						{CaseStudies.filter((elm) => elm.id !== mainContent.id).map(
							(elm, index) => (
								<motion.div
									key={elm.id}
									custom={index}
									initial='hidden'
									animate={isInView ? 'visible' : 'hidden'}
									variants={itemVariants}
								>
									<CaseStudyItem
										{...elm}
										onSelect={(e) =>
											setMainContent(
												CaseStudies.find((elm) => elm.id === e) ||
													CaseStudies[0]
											)
										}
									/>
								</motion.div>
							)
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
