import './Services.css';
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';

import contigent from '../../assets/contigent.jpg';
import exclusive from '../../assets/exclusive.jpg';
import project from '../../assets/projectbased.jpg';
import retained from '../../assets/retained.jpg';

export const Services = () => {
	const ref = useRef(null);
	const isInView = useInView(ref);
	return (
		<div className='fadedown lg:max-w-4xl max-w-2xl mx-auto p-2' id='services'>
			<div>
				<h2 className='text-xl font-semibold leading-8 tracking-tight text-center text-yellow-500'>
					Services
				</h2>
			</div>
			<p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 text-left sm:text-4xl '>
				Looking To Scale Your Team? Choose From One Of Our Services
			</p>
			<div className='gap-y-4 flex flex-wrap lg:grid my-8'>
				<motion.div
					initial='offscreen'
					whileInView='onscreen'
					animate={isInView ? 'onscreen' : 'offscreen'}
					ref={ref}
					// viewport={{ once: true, amount: 0.8 }}
					className='col-span-3 firstContainer shadow items-center flex flex-wrap lg:grid row-span-1 grid-cols-2 grid-rows-1 justify-between p-4 rounded-xl'
				>
					<div className='p-6'>
						<h3 className='mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl'>
							Retained
						</h3>
						<p className='mt-2 text-sm text-white'>
							We conduct an extensive search and selection process to find and hire
							top-level executives to lead your business. We thoroughly examine your
							business and stakeholders to ensure a perfect fit for every position.
							For every role we focus on, we typically evaluate over 250 candidates,
							conduct 50 screening calls and present you with a thoughtfully curated
							shortlist of candidates.
						</p>
					</div>
					<div>
						<img
							src={retained}
							alt='retained'
							className='p-2 rotate-[5deg] rounded-2xl shadow-2xl'
						></img>
					</div>
				</motion.div>
				<div className=' col-span-3 gap-4 flex flex-wrap lg:grid lg:grid-cols-4 justify-between'>
					<div className='secondContainer shadow rounded-xl col-span-2'>
						<div className='p-6'>
							<h3 className='font-bold mt-2 sm:text-4xl text-3xl text-gray-600 tracking-tight'>
								Exclusive
							</h3>
							<p className='mt-2 text-gray-700 text-sm'>
								Collaborate exclusively with J&T as your sole recruitment partner
								and we take care of all your hiring needs. If you choose us as your
								exclusive partners we will ensure that our team conducts a thorough
								exploration of your business, including its culture and the
								important stakeholders involved in the hiring process. This allows
								us to make precise and suitable matches that align with your
								organization's values and requirements.
							</p>
						</div>

						<img src={exclusive} alt='exclusive' className='p-2'></img>
					</div>
					<div className='col-span-2 flex flex-col justify-between rounded-xl shadow thirdContainer'>
						<div className='p-6'>
							<h3 className='mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl'>
								Project Based
							</h3>
							<p className='mt-2 text-base text-white'>
								Are you anticipating a significant increase in hiring? Maybe you're
								seeking a partnership for multiple hires simultaneously? We can
								create a customized project plan to guarantee timely and
								cost-effective delivery of these hires.
							</p>
						</div>
						<img src={project} alt='project' className='p-2 rounded-2xl'></img>
					</div>
				</div>
				<div className='fourthContainer shadow flex flex-wrap lg:grid grid-cols-2 col-span-3 justify-between p-4 rounded-xl'>
					<div className='p-6'>
						<h3 className='mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl'>
							Contingent
						</h3>
						<p className='mt-2 text-base text-white'>
							Still not sure if we are the right partners for you? Hire us on a
							contingent basis so that you can see the quality of work that we do
							before signing us up as your exclusive recruitment partners.
						</p>
					</div>

					<img
						src={contigent}
						alt='contigent'
						className='h-64 object-cover p-2 rounded-2xl w-100'
					></img>
				</div>
			</div>
		</div>
	);
};
