import sixBDigital from '@/assets/CaseStudy/6Bdigital.jpg';
import Avrillo from '@/assets/CaseStudy/Avrillo.jpg';
import dhl from '@/assets/CaseStudy/dhl.jpg';
import Glider from '@/assets/CaseStudy/Glider.jpg';
import Hm from '@/assets/CaseStudy/hm.jpg';
export const CaseStudies = [
	{
		id: 'Case Study 1',
		title: 'Multiple Hire Campaign For Senior PHP Developers - Avrillo Conveyancing',
		description:
			"We partnered with Avrillo Conveyancing, one of UK's leading Conveyancing Solicitor firm, to recruit 3 Senior PHP Developers for building an internal management system to scale their operations. The role required the successful candidates to work with minimal supervision on the task assigned to them. Within just 12 days, we identified and placed 3 exceptional senior developers to successfully strengthen their software engineering team.",
		imageUrl: Avrillo,
		tags: ['Recruitment', 'PHP Developers'],
	},
	{
		id: 'Case Study 2',
		title: 'Senior Technical Delivery Manager – 6B Digital',
		description:
			'6B Digital, one of the UK’s leading engineering consultancies, sought a talented Technical Delivery Manager to join their growing team in Leeds. They wanted a candidate who had experience working with agile methodologies to help them grow their expanding presence in the UK Healthcare Market to deliver various contracts with different NHS Trusts. We successfully placed a highly skilled Delivery Manager who has been an integral part of their team ever since and has helped them accelerate their growth into new sectors!',
		imageUrl: sixBDigital,
		tags: ['Engineering', 'Healthcare'],
	},
	{
		id: 'Case Study 3',
		title: 'Head of Franchising and Store Operations – Alshaya Group',
		description:
			'Alshaya Group, a global leader in brand franchise operations, engaged us to recruit a Head of Franchising and Store Operations for their H&M, Charlotte Tilbury, and Starbucks operations in Dubai. The ideal candidate needed to be multilingual and highly experienced in franchise management. Leveraging our extensive network, we identified and secured a suitable candidate within 10 days, enabling Alshaya to continue their dominance within the region.',
		imageUrl: Hm,
		tags: ['Franchising', 'Operations'],
	},
	{
		id: 'Case Study 4',
		title: 'Chinese-Speaking Finance Manager – DHL',
		description:
			'DHL tasked us with finding a Chinese-speaking Finance Manager based in Norwich, UK—a challenging search due to the niche skill set required. Through strategic outreach and our expansive network, we located an outstanding candidate, fulfilling the role and addressing DHL’s unique requirements.',
		imageUrl: dhl,
		tags: ['Finance', 'Multilingual'],
	},
	{
		id: 'Case Study 5',
		title: 'Senior Dotnet Developer And QA Engineer - Glider Tech',
		description:
			'Glider Tech is SAAS Company based in the UK who provides Digital information management software for project handovers and building operations. They were looking for an experienced Senior Dotnet Developer and a QA Engineer to join their team to expand their Engineering team. Both candidates had to be eligible for security clearances as Glider had clients within the Defense industry. By using our rigorous search methodology and carefully mapping out the talent pool available to us, we were able to help them find 2 rockstar candidates for both the the Dotnet Developer and the QA Engineer roles to help them grow their footprints within the UK Defense and Construction industry.',
		imageUrl: Glider,
		tags: ['Dotnet', 'QA'],
	},
];
