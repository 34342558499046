import { ArrowRightIcon } from '@heroicons/react/24/outline';

import Image from '../Image';
export const CaseStudyItem = ({
	title,
	tags,
	description,
	id,
	imageUrl,
	onSelect,
}: {
	title: string;
	tags: string[];
	description: string;
	id: string;
	imageUrl: string;
	onSelect: (e: string) => void;
}) => {
	return (
		<div
			className='flex flex-row gap-2 h-44 relative group cursor-pointer hover:shadow-sm rounded-md'
			onKeyDown={() => onSelect(id)}
			onClick={() => onSelect(id)}
			role='button'
			tabIndex={0}
		>
			<div className='absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-max bg-gray-800 text-white text-xs font-medium py-1 px-2 rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
				Click to read more
				<div className='absolute left-1/2 -bottom-1 translate-x-[-50%] w-2.5 h-2.5 bg-gray-800 rotate-45'></div>
			</div>
			<Image
				src={imageUrl}
				alt='mainContainerLandingImage'
				className='h-full w-1/2 object-cover rounded-tl-sm rounded-bl-sm'
			/>
			<div className='flex flex-col gap-1 justify-between py-1 pr-2 text-left w-1/2'>
				<div className='flex flex-row gap-2 items-center'>
					<p className='text-yellow-500 text-sm font-semibold'>{id}</p>
				</div>
				<h3 className=' text-lg/2  truncate  font-semibold text-gray-900 group-hover:text-gray-600'>
					{title}
				</h3>

				<p className=' line-clamp-2 text-sm/6 text-gray-400 tracking-tight '>
					{description}
				</p>
				<div className='flex flex-wrap gap-2'>
					{tags.map((elm) => (
						<span
							key={elm}
							className='inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20'
						>
							{elm}
						</span>
					))}
				</div>
				<button
					type='button'
					className='inline-flex items-center rounded-full w-fit align-middle hover:border bg-white px-2 py-1 text-xs font-semibold text-yellow-500'
				>
					Read More
					<ArrowRightIcon aria-hidden='true' className='w-3 h-3 ml-1 text-yellow-500' />
				</button>
			</div>
		</div>
	);
};
