import { useRoutes } from 'react-router-dom';

import Banner from '@/components/banner/banner';
import { CaseStudy } from '@/components/CaseStudy/CaseStudy';
import Clients from '@/components/Clients/client';
import { Footer } from '@/components/Footer/Footer';
import Navbar from '@/components/navbar/navbar';
import Promises from '@/components/Promises/promises';
import { QuestionsContainer } from '@/components/questionContainer/QuestionContainer';
import { QuestionContainerGoogleForm } from '@/components/questionContainer/QuestionContainerGoogleForm';
import { Services } from '@/components/Services/Services';
import Stats from '@/components/Stats/Stats/Stats';
import Testimonials from '@/components/Testimonials/testimonials';

interface IAppRoutesProps {
	isTopBarVisible: boolean;
}

export const AppRoutes = (props: IAppRoutesProps) => {
	const { isTopBarVisible } = props;
	const routes = [
		{
			path: '/*',
			element: (
				<>
					<Navbar isVisible={isTopBarVisible}></Navbar>
					<Banner></Banner>
					<Clients></Clients>
					<Stats />
					<Services />
					<CaseStudy />
					<QuestionContainerGoogleForm />
					<Promises></Promises>
					<Testimonials></Testimonials>
					<Footer></Footer>
				</>
			),
		},
		{
			path: '/form',
			element: (
				<>
					<Navbar isVisible={isTopBarVisible}></Navbar>
					<Banner></Banner>
					<Clients></Clients>
					<Promises></Promises>
					<QuestionsContainer></QuestionsContainer>
					<Testimonials></Testimonials>
					<Footer></Footer>
				</>
			),
		},
	];
	const element = useRoutes([...routes]);
	return <>{element}</>;
};
