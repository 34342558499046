import './promises';
import {
	ClockIcon,
	ChartBarIcon,
	ShieldCheckIcon,
	PresentationChartBarIcon,
} from '@heroicons/react/24/outline';

const Promises = () => {
	const features = [
		{
			name: 'A First Class Approach ',
			description:
				'This encapsulates our ethos and processes. We strive each day to strike a balance between providing you with the best candidates while also being a pleasure to work with.',
			icon: PresentationChartBarIcon,
		},

		{
			name: 'Always Updated',
			description:
				"If we promise to keep you informed, we'll do it. We are aware of how frustrating ineffective communication can be. You can be assured that you will receive updates via phone, email, or text message through every step of the recruitment process.",
			icon: ChartBarIcon,
		},
		{
			name: 'Looking Ahead',
			description:
				'Our goal is to find you the best candidates and thus we will take the initiative to meet and understand your needs before bringing your story and narrative into the market. We do not just sit back and wait for applications.',
			icon: ShieldCheckIcon,
		},
		{
			name: 'Fast & Reliable Service',
			description:
				"We'll save you time so you can concentrate on your business while we explore market for your ideal candidate.",
			icon: ClockIcon,
		},
	];
	return (
		<div className='bg-white p-2'>
			<div className=' max-w-[56rem] mx-auto'>
				<div className='bg-gray-900 rounded-3xl'>
					<div className='lg:flex-auto lg:mx-0 lg:text-left mx-auto text-center'>
						<div className='py-12 lg:px-0'>
							<div>
								<div className='lg:text-center'>
									<h2 className='text-lg font-semibold leading-8 tracking-tight text-yellow-500'>
										Why Choose Us?
									</h2>
									<p className='mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl'>
										Inspiring Excellence Through Our Mission And Values
									</p>
									<p className='mt-6 text-lg leading-8 text-gray-400 px-10'>
										We are a group of talented individuals whose vision is to
										recruit the finest graduate and experienced talents for high
										potential start-ups and well established businesses
									</p>
								</div>
								<div className='p-12'>
									<dl className='grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16'>
										{features.map((feature) => (
											<div key={feature.name} className='relative pl-16'>
												<dt className='font-semibold leading-7 text-base text-white'>
													<div className='absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-yellow-500'>
														<feature.icon
															className='h-6 w-6 text-white'
															aria-hidden='true'
														/>
													</div>
													{feature.name}
												</dt>
												<dd className='mt-2 text-base leading-7 text-gray-400'>
													{feature.description}
												</dd>
											</div>
										))}
									</dl>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Promises;
