import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';

import sixb from '../../assets/6B.svg';
import airbnb from '../../assets/Airbnb_logo.png';
import althan from '../../assets/Alt-HAN-Co-Logo.svg';
import arunEstates from '../../assets/arunEstates.svg';
import avrillo from '../../assets/avrillo.svg';
import bright from '../../assets/bright-logo-alt.svg';
import goodsixty from '../../assets/goodsixty.svg';
import hsbc from '../../assets/hsbc.svg';
import infosys from '../../assets/infosys.png';
import inpsyde from '../../assets/inpsyde.svg';
import intaforensic from '../../assets/intaforensic.svg';
import glider from '../../assets/logo_glider.png';
import netWatchLogo from '../../assets/netwatch-logo.png';
import nimble from '../../assets/nimble.png';
import roku from '../../assets/roku-logo.svg';
import scottish from '../../assets/scottish.svg';
import trainline from '../../assets/trainline-mint.svg';
import transferWise from '../../assets/transferwise.svg';
import twilo from '../../assets/twilo-creative-logo.svg';
import ukDrivingControl from '../../assets/ukParkingControl.svg';

import './client.css';

const Clients = () => {
	const images = [
		{ src: trainline, alt: 'trainline' },
		{ src: sixb, alt: 'sixb' },
		{ src: hsbc, alt: 'hsbc' },
		{ src: glider, alt: 'glider' },
		{ src: intaforensic, alt: 'intaforensic' },
		{ src: twilo, alt: 'twilo' },
		{ src: goodsixty, alt: 'goodsixty' },
		{ src: infosys, alt: 'infosys' },
		{ src: scottish, alt: 'scottish' },
		{ src: transferWise, alt: 'transferWise' },
		{ src: arunEstates, alt: 'arunEstates' },
		{ src: roku, alt: 'roku' },
		{ src: nimble, alt: 'nimble' },
		{ src: netWatchLogo, alt: 'netWatchLogo' },
		{ src: airbnb, alt: 'airbnb' },
		{ src: althan, alt: 'althan' },
		{ src: ukDrivingControl, alt: 'ukDrivingControl', background: '#3db6e3', className: 'p-1' },
		{ src: avrillo, alt: 'avrillo' },
		{ src: bright, alt: 'bright', background: '#162a3b' },
		{ src: inpsyde, alt: 'inpsyde' },
	];
	const ref = useRef(null);
	const isInView = useInView(ref, { once: true });

	const staggerVariants = {
		hidden: { opacity: 0, x: -50 },
		visible: (index: number) => ({
			opacity: 1,
			x: 0,
			transition: {
				delay: index * 0.1, // Adjust the delay as needed
			},
		}),
	};
	return (
		<div className='bg-white '>
			<div className='mx-auto max-w-7xl px-6 lg:px-8'>
				<h2 className='text-center text-lg font-semibold fadedown leading-8 text-gray-600'>
					Empowering Businesses With Innovative Solutions
				</h2>
				<div
					ref={ref}
					className='mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5'
				>
					{images.map((image, index) => (
						<motion.div
							key={index}
							variants={staggerVariants}
							initial='hidden'
							animate={isInView ? 'visible' : 'hidden'}
							custom={index}
							className={`col-span-2 w-full object-contain lg:col-span-1 ${
								image.background ? `bg-[${image.background}]` : ''
							}`}
						>
							<img
								src={image.src}
								alt={image.alt}
								className={image.className}
								width={158}
								height={48}
								style={{
									backgroundColor: image.background,
								}}
							/>
						</motion.div>
					))}
				</div>
			</div>
		</div>
	);
};
export default Clients;
