import Image from '../Image';
export const MainContainer = ({
	title,
	tags,
	description,
	id,
	imageUrl,
}: {
	title: string;
	tags: string[];
	description: string;
	id: string;
	imageUrl: string;
}) => {
	return (
		<div className='flex flex-col'>
			<Image
				src={imageUrl}
				alt='mainContainerLandingImage'
				className='h-[24rem] object-cover rounded-md'
			/>
			<div className='flex flex-col gap-1 text-left mt-6'>
				<div className='flex flex-row gap-2 items-center'>
					<p className='text-yellow-500 text-sm font-semibold'>{id}</p>
				</div>
				<h3 className='text-lg/6 font-semibold text-gray-900 group-hover:text-gray-600'>
					{title}
				</h3>
				<p className=' line-clamp-10 text-sm/6 text-gray-500 tracking-tight'>
					{description}
				</p>
				<div className='flex flex-wrap gap-2'>
					{tags.map((elm) => (
						<span
							key={elm}
							className='inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20'
						>
							{elm}
						</span>
					))}
				</div>
			</div>
		</div>
	);
};
